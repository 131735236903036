<template>
    <div>
        <v-layout row wrap :align-center="!wideLayout" :justify-center="!wideLayout" :fill-height="!wideLayout">
            <v-flex xs12 sm12 :md12="wideLayout" :lg12="wideLayout" :md10="!wideLayout" :lg10="!wideLayout" :xl8="!wideLayout">
                

                <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="loading"
                class="elevation-1"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageOptions: [5,10,15,25,50, 100]
                }"
                >
                <template v-slot:top>
                    <v-toolbar class="elevation-1" color="white">
                        <v-toolbar-title class="font-weight-regular body-1">{{ settings.title}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-flex xs4 md4 lg4 v-show="breakPoint">
                            <!--<v-text-field class="pt-4"  label="Išči..." @keyup.esc="search = ''" v-model="search" append-icon="mdi-magnify" single-line></v-text-field>-->
                            <v-text-field class="pt-4"  label="Search..." @keyup.esc="search = ''"  @keyup="doSearch()" v-model="search" append-icon="mdi-magnify" single-line clearable @click:clear="doSearch()"></v-text-field>
                        </v-flex>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <v-tooltip v-if="btnRefresh" top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="refreshDataTable" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                    <v-icon>mdi-sync</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh data</span>
                        </v-tooltip>
                        <v-tooltip v-if="btnToggleColumns" top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggleColumns()" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <span>Show / hide columns</span>
                        </v-tooltip>
                    </v-toolbar>
                    <br>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_info"
                                color="black"
                                small
                                class="mr-2"
                                @click="viewItemInfoDialog(item)"
                                v-on="on"
                            >
                                mdi-magnify
                            </v-icon>
                        </template>
                        <span>View details</span>
                    </v-tooltip>
                    <v-tooltip top v-if="checkApplicationPermissions([]) && item.export_status == 3">
                        <template v-slot:activator="{ on }">
                            <!--<a :href="downloadItemUrl(item)" style="text-decoration:none;">-->
                            <v-icon
                                id="dt_btn_download"
                                color="success"
                                small
                                class="mr-1"
                                v-on="on"
                                @click="downloadItem(item)"
                            >
                                mdi-download
                            </v-icon>
                            <!--</a>-->
                        </template>
                        <span>Download</span>
                    </v-tooltip>
                    <v-tooltip top v-if="checkApplicationPermissions([])">
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_delete"
                                color="error"
                                small
                                @click="deleteItem(item)"
                                v-on="on"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Delete</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.name="{ item }">
                    <v-chip label class="" v-if="item != null && item.name !=='null' && item.name !== ''" color="gray"  dark small>{{ item.name }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.user="{ item }">
                    <!--<v-chip label class="" v-if="item.user != null && item.user !=='null' && item.user !== ''"  dark  small>{{ item.user.name }}</v-chip>-->
                    <span v-if="item.user != null && item.user !=='null' && item.user !== ''">{{ item.user.name }}</span>
                    <span v-else></span>
                </template>
                

                <template v-slot:item.file_type_for_humans="{ item }">
                    <v-chip label class="" v-if="item.file_type_for_humans != null && item.file_type_for_humans !=='null' && item.file_type_for_humans !== ''" :color="getFileTypeLabelColor(item)" dark small>{{ getFileTypeName(item.file_type_for_humans) }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.export_status_name="{ item }">
                    <v-chip label class="" v-if="item.export_status != null && item.export_status !=='null' && item.export_status !== ''" :color="getExportStatusLabelColor(item)" dark small>{{ getExportStatusLabelName(item) }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.group="{ item }">
                    <!--<v-chip label class="" v-if="item.group != null && item.group !=='null' && item.group !== ''"  dark small>{{ getGroupLabelName(item) }}</v-chip>-->
                    <span v-if="item.group != null && item.group !=='null' && item.group !== ''">{{ getGroupLabelName(item) }}</span>
                    <span v-else></span>
                </template>

                <template v-slot:item.file_size_readable="{ item }">
                    <v-chip label class="" v-if="item.file_size_readable != null && item.file_size_readable !=='null' && item.file_size_readable !== ''" :color="getFileSizeLabelColor(item)" dark small>{{ item.file_size_readable }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.done="{ item }">
                    <v-chip label class="" v-if="item.done != null && item.done !=='null' && item.done !== ''" :color="getDoneColor(item.done)"  dark small>{{ item.done }}%</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.memory_peak_usage="{ item }">
                    <v-chip label class="" v-if="item.memory_peak_usage != null && item.memory_peak_usage !=='null' && item.memory_peak_usage !== ''" :color="getMemoryPeakUsageColor(item.memory_peak_usage)"  dark small>{{ item.memory_peak_usage }} MiB</v-chip>
                    <span v-else></span>
                </template>
                
                <template v-slot:progress>
                    <v-progress-linear absolute indeterminate :height="progressHeight()"></v-progress-linear>
                </template>
                <template slot="no-data">
                    <div class="no_data" v-show="!searchQuery">{{ (this.loading) ? "Data processing in progress..." : "No data to display." }}</div>
                    <div class="no_data" v-show="searchQuery">{{ (this.loading) ? 'Data processing for keyword "'  + this.search + '" is in progress...' :  `No results for keyword »${this.search}«.`}}</div>
                </template>
                <template slot="loading">
                    <div class="no_data">Data processing in progress ...</div>
                </template>
                </v-data-table>
            </v-flex>

            <v-dialog v-model="dialog" persistent max-width="490">
                <v-card>
                    <v-card-title></v-card-title>
                    <v-card-text>
                        <v-icon text>mdi-delete</v-icon>
                        {{ dialog_text }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="error" @click.native="dialogCancel()">Cancel</v-btn>
                        <v-btn text :loading="loading" color="success" @click.native="dialogConfirm()">Yes, continue</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>

        <export-info-dialog v-if="$store.getters.parametersData"></export-info-dialog>
    </div>
</template>

<script>
import { post, get, put, destroy, API_ENDPOINT, DEMO_API_ENDPOINT } from '@/helpers/api'
import { EventBus } from '@/main.js'
import { getUserApplicationRole, getUserApplicationPermissions, checkPermissions, capitalize } from '@/helpers/utilities'
import ExportInfoDialog from '@/components/ExportInfoDialog.vue'

export default {
    props: ['settings', 'queryParameters', 'boxedLayout'],
    name: 'exports-data-table',

    components: {
        ExportInfoDialog
    },

    data: () => ({
        search: '',
        totalItems: 0,
        items: [],
        loading: true,
        options: {},
        rowsPerPageItems: [5,10,15,25,50,100, -1], 
        interval: null,
        dialog: false,
        dialog_text: '',
        itemToDelete: null,
        searchTimer: null
    }),

    watch: {
        options: {
            handler (value) {
            this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })

                //window.console.log(this.options)
                //window.console.log(this.params)

                let itemsPerPage = value.itemsPerPage

                //window.console.log("Items per page: ", itemsPerPage)
                this.$store.commit("APP_SETTING", {
                    setting: "dt_exports",
                    key: "items_per_page",
                    value: itemsPerPage
                })
            },
            deep: true,
        },

        /*search(newValue) {
            this.$store.commit('SET_SEARCH', newValue)
            this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })
        },*/

        searchFromStore(newValue) {
            this.search = newValue
            this.doSearch()
        }

        /*items(newValue) {
            window.console.log(this.items)
        }*/
    },

    computed: {
        params(nv) {
            return {
                ...this.options,
                query: this.search
            }
        },

        searchQuery() {
           return (this.search != null && this.search.trim() != '') ? true : false;
       },

       btnRefresh() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.refresh) {
               return true
           }

           return false
       },

       btnExport() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.export) {
               return true
           }

           return false
       },

       btnAddNew() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.addNew) {
               return true
           }

           return false
       },

       btnToggleColumns() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.toggleColumns && 
                this.settings.eventBus && this.settings.eventBus.toggleColumnsId != null && this.settings.eventBus.toggleColumnsId.trim() != ''
           ) {
               return true
           }

           return false
       },

       breakPoint() {
           if(this.$vuetify.breakpoint.mdAndUp) {
                return true;
            }

             return false;
       },

       apiParams() {
           let params = {
                ...this.options,
                query: this.search,
                columns: this.settings.headers.map(header => {
                    return header.value
                })
            }

            if(this.queryParameters && this.queryParameters != null) {
                let queryParams = this.queryParameters
                params = {
                    ...params,
                    ...queryParams
                }
            }

            //window.console.log("queryParams")
            //window.console.log(params)

           return params;
       },

       headers() {
           return this.settings.headers.filter(header => {
               if(header.visibility) {
                   if(header.value == 'action' && !this.checkApplicationPermissions(header.permissions)) {
                       return;
                   }
                   return header
               }
           });
       },

       wideLayout() {
           if(this.boxedLayout !== undefined) {
               if(this.boxedLayout === true) {
                   return false
               }

               return true
           }

           return true
       },

       searchFromStore: {
           get() {
               return this.$store.getters.search
           },

           set(value) {
               this.$store.commit('SET_SEARCH', this.search)
           }
       }
    },

    methods: {
        goToFirstPage() {
            this.$set(this.options, 'page', 1)
        },

        checkApplicationPermissions(requiredPermissions) {
            return checkPermissions(requiredPermissions, getUserApplicationPermissions())
        },

        getDataFromApi () {
            this.loading = true
            
            if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'totalItems')) {
                if(this.settings.eventBus.totalItems) {
                    EventBus.$emit(this.settings.eventBus.totalItems.event, {progress: true, value: 0})
                }
            }

            if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'sumItems')) {
                if(this.settings.eventBus.sumItems) {
                    EventBus.$emit(this.settings.eventBus.sumItems.event, {progress: true, queryParameters: this.apiParams})
                }
            }

            return new Promise((resolve, reject) => {
                //post(API_ENDPOINT + this.settings.endpoint, this.apiParams)
                post(API_ENDPOINT + this.settings.endpoint, this.apiParams)            
                .then(response => {
                   // window.console.log("API data")
                   // window.console.log(response.data)
                    const {
                       sortBy,
                       sortDesc, 
                       page,
                       itemsPerPage 
                    } = this.options;

                    //this.pagination.rowsPerPage = response.data.meta.per_page
                    //console.log(response)
                    const items = response.data.data
                    const totalItems = response.data.meta.total

                    if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'totalItems') ) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {value: totalItems})
                        }
                    }

                    resolve({items, totalItems})
                })
                .catch(error => {
                    if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {value: 0})
                        }
                    }
                    reject(error)
                })
                .finally(() => {
                    this.loading = false
                    if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {progress: false})
                        }
                    }
                })
            })
      },

      editItem(item) {
           //console.log(item)
           if(this.settings && this.settings.editItem && this.settings.editItem.route) {
               //window.console.log(this.settings.editItem.route)
               //window.console.log(item[this.settings.editItem.routeParameterValue])

               this.$router.push({
                   name: this.settings.editItem.route,
                   params: {
                       id: item[this.settings.editItem.routeParameterValue]
                   }
               })
           }

           if(this.settings && this.settings.editItem && Object.prototype.hasOwnProperty.call(this.settings.editItem, 'dialog') ) {
               if(this.settings.editItem.dialog == true && this.settings.eventBus.editDialog != '') {
                   EventBus.$emit(this.settings.eventBus.editDialog, {item: item})
               }
           }

       },

       downloadItemUrl(item) {
           //console.log(item)
           let itemLocation = ""
           
           itemLocation = API_ENDPOINT + this.settings.endpoint + "/" + item.uuid + "/download" ;

           window.console.log(itemLocation);

           return itemLocation;
           
       },

       downloadItem(item) {
           let vm = this;
           let fileExtension = item.name.split(".").pop();
           let fileName = item.id + "_" + item.name
           

            // window.console.log("downloadItemUrl")
            // window.console.log(item)

            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('DOWNLOAD_FILE', item.uuid)
           .then(response => {
                //window.console.log(response)
                vm.$store.commit('snackbarDisplay', false)
                //vm.$store.commit('snackbarText', "Prenos datoteke " + item.name + " je uspel.")
                vm.$store.commit('snackbarText', "Document " + item.name + " successfully downloaded.")
                vm.$store.commit('snackbarMode', '')
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
           })
           .catch(error => {
                 vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarText', "An error occurred while downloading document " + item.name + ".")
                vm.$store.commit('snackbarMode', '')
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)
           })
           .finally(() => {
               setTimeout(() => {
                   vm.$store.commit('SET_PROGRESS', false)
               }, 600);
           })
       },

       deleteItem(item) {
           this.itemToDelete = item
           //console.log(item)
            if(Object.prototype.hasOwnProperty.call(this.settings, 'deleteItem')  && this.settings.deleteItem != null) {
                this.dialog_text = this.settings.deleteItem.confirmationText.replace('{str_to_replace}', this.itemToDelete[this.settings.deleteItem.replaceStrWithColumnValue])
            }
            else {
                this.dialog_text = 'Are you sure you want to delete document' + this.itemToDelete.id + '?'
            }
           this.dialog = true

           //console.log(this.itemToDelete)
       },

       viewItemInfoDialog(item) {
           EventBus.$emit('VIEW_EXPORT_ITEM_DIALOG', item);
       },

       dialogConfirm() {
           new Promise( (resolve, reject) => {
               this.loading = true
               if(this.settings.deleteItem.vuexAction != '' && this.settings.deleteItem.vuexAction != null) {
                   //this.$store.dispatch(this.settings.deleteItem.vuexAction, this.itemToDelete.id)
                   this.$store.dispatch(this.settings.deleteItem.vuexAction, this.itemToDelete[this.settings.deleteItem.routeParameterValue])
                    .then(response => {
                        this.dialog = false
                        this.loading = false    
                        this.$store.state.snackbar.mode = ''
                        this.$store.state.snackbar.display = true
                        this.$store.state.snackbar.text = this.settings.deleteItem.dialogConfirmSuccessText.replace('{str_to_replace}', this.itemToDelete[this.settings.deleteItem.replaceStrWithColumnValue])

                        this.itemToDelete = null
                        this.refreshDataTable()

                    })
                    .catch(error => {
                        //window.console.log(error)
                        this.dialog = false
                        this.loading = false    
                        this.$store.state.snackbar.mode = 'multi-line'
                        this.$store.state.snackbar.display = true
                        this.$store.state.snackbar.text = this.settings.deleteItem.dialogConfirmFailText.replace('{str_to_replace}', this.itemToDelete[this.settings.deleteItem.replaceStrWithColumnValue])
                        this.itemToDelete = null
                    })
               } else {
                    this.dialog = false
                    this.loading = false    
                    this.$store.state.snackbar.mode = 'multi-line'
                    this.$store.state.snackbar.display = true
                    this.$store.state.snackbar.text = this.settings.deleteItem.dialogConfirmFailText.replace('{str_to_replace}', this.itemToDelete[this.settings.deleteItem.replaceStrWithColumnValue])
                    this.itemToDelete = null
               }
           })
        },

       dialogCancel() {
            this.dialog_confirm = false
            this.dialog = false
            this.itemToDelete = null
       },

       addNewItem() {
           if(this.settings.toolbar.buttons.addNewRoute != '' && this.settings.toolbar.buttons.addNewRoute != null) {
               this.$router.push({name: this.settings.toolbar.buttons.addNewRoute})
           }
       },

       refreshDataTable() {
        this.goToFirstPage()
           this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })
       },

       toggleColumns() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.toggleColumns && 
                this.settings.eventBus && this.settings.eventBus.toggleColumnsId != null && this.settings.eventBus.toggleColumnsId.trim() != '') {
                    EventBus.$emit(this.settings.eventBus.toggleColumnsId, {
                        toggleColumnsMenu: true
                    });
            }
       },

       toggleDataTableColumns(data) {
           if(Object.prototype.hasOwnProperty.call(data, 'dataTableHeaders') ) {
               //window.console.log(data)
                for(var i=0; i<this.settings.headers.length; i++) {
                    var headerFound = false;
                    for(var k=0; k<data.dataTableHeaders.length; k++) {
                        if(this.settings.headers[i].value == data.dataTableHeaders[k]) {
                            this.settings.headers[i].visibility = true
                            headerFound = true
                        }
                    }

                    if(headerFound == false) {
                        //console.log(this.dataTableSettings.headers[i])
                        this.settings.headers[i].visibility = false
                    }
                }

           }
       },

       progressHeight() {
           if(Object.prototype.hasOwnProperty.call(this.settings, 'progressHeight')  && this.settings.progressHeight != null && this.settings.progressHeight != '') {
               return this.settings.progressHeight
           }

           return 3
       },

       getFileTypeLabelColor(item) {
           if(item.file_type_for_humans == 'excel') {
               return 'green'
           }

           if(item.file_type_for_humans == 'text/plain') {
                return "#6D4C41"
           }

           return ""
       },

       getFileSizeLabelColor(item) {
            //window.console.log(item)
           if(item.file_size_readable !== "" && item.file_size_readable !== null) {
               let sizeValue = item.file_size_readable
               if(sizeValue.includes('KB') || sizeValue.includes('kb') ) {
                   return 'success';
               }

               let fileSizeMB = (item.file_size !== null && item.file_size !== "") ?  item.file_size / (1024 ** 2): null;

               if(fileSizeMB !== null && fileSizeMB < 30) {
                    return 'success'
               }

           }

           return "error";
       },

       getExportStatusLabelColor(item){
           if(item.export_status == 1) {
               return "";
           }

           if(item.export_status == 2) {
               return "warning";
           }

           if(item.export_status == 3) {
                return "info";
           }

           if(item.export_status == 4) {
                return "error";
           }

           return "";
       },

       getDoneColor(done) {
            if(done !== null) {
                if(done < 50) {
                    return "error"
                }

                if(done >= 50 && done < 100) {
                    return "warning"
                }

                if(done >= 100) {
                    return "success"
                }
            }

            return "error"
       },

       getMemoryPeakUsageColor(mpu) {
            if(mpu !== null) {
                if(mpu < 128) {
                    return "success"
                } else {
                    return "error"
                }
            }

            return ""
       },

       getExportStatusLabelName(item) {
           if(item.export_status == 1) {
               return item.export_status_name;
           }

           if(item.export_status == 2) {
               return item.export_status_name;
           }

           if(item.export_status == 3) {
                return item.export_status_name;
           }

           if(item.export_status == 4) {
                return item.export_status_name;
           }

           return item.export_status_name;
       },

       getGroupLabelName(item) {
           if(item.group == 'events') {
               return "Dogodki"
           }

           if(item.group == 'users') {
               return "Uporabniki"
           }

           if(item.group == 'parking_gate_events') {
               return 'Zapornice (dogodki)'
           }

           if(item.group == 'registration_numbers') {
               return "Enkratni prehodi";
           }

           return "";
       },

       getFileTypeName(name) {
            return name.toUpperCase()
       },

       doSearch() {
           let vm = this
           this.goToFirstPage()
           if(this.searchTimer) {
               clearTimeout(this.searchTimer)
               this.searchTimer = null
           }

           this.searchTimer = setTimeout(() => {
               vm.getDataFromApi()
                .then(data => {
                   vm.items = data.items
                   vm.totalItems = data.totalItems
               })
               .catch(error => {
                   vm.items = [];
                   vm.totalItems = 0;
                   window.console.log(error)
               })
           }, 600)
       }
    },

    created() {
        /*window.console.log("DataTableSettings");
        window.console.log(this.settings)

        window.console.log("Query Parameters")
        window.console.log(this.queryParameters)*/
    },

    mounted() {
        if(this.settings && this.settings.sortColumn) {
            this.options.sortBy = this.settings.sortColumn
        }

        if(this.settings && this.settings.sortDirection && this.settings.sortDirection == 'desc') {
            this.options.sortDesc = this.settings.sortDirection
        }

        if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') ) {
            if(this.settings.eventBus.refreshDataTable != null && this.settings.eventBus.refreshDataTable.trim() != '' ) {
                EventBus.$on(this.settings.eventBus.refreshDataTable, (data) => {
                    this.refreshDataTable()
                })
            }

            if(this.settings.eventBus.search != null && this.settings.eventBus.search.trim() != '') {
                EventBus.$on(this.settings.eventBus.search, (data) => {
                    this.search = data
                })
            }
        }

        if(this.settings.id != null && this.settings.id !== undefined) {
                EventBus.$on(this.settings.id, (data) => {
                    this.toggleDataTableColumns(data)
                })
            }

        if(this.settings && this.settings.updateInterval) {
            this.interval = setInterval(() => {
                if(this.loading != true) {
                    this.getDataFromApi()
                    .then(data => {
                        this.items = data.items
                        this.totalItems = data.totalItems
                    })
                    .catch(error => {
                        this.items = [];
                        this.totalItems = 0;
                        window.console.log(error)
                    })
                }
            }, process.env.VUE_APP_DATA_EXPORT_TABLE_REFRESH_INTERVAL || 30000)
       }

       if(this.settings && this.settings.rowsPerPage) {
           this.options.itemsPerPage = this.settings.rowsPerPage
       }

       //window.console.log("Boxed Layout: " + this.boxedLayout)
       //window.console.log(this.items)
    },

    beforeDestroy() {
        clearTimeout(this.searchTimer)
    },

    destroyed() {
        clearInterval(this.interval)
        if(this.settings && this.settings.eventBus  && this.settings.eventBus.refreshDataTable) {
            if(this.settings.eventBus.refreshDataTable != null && this.settings.eventBus.refreshDataTable.trim() != '' ) {
                EventBus.$off(this.settings.eventBus.refreshDataTable)
            }
        } 

        EventBus.$off(this.settings.id)
    }
}

</script>

<style scoped>
    .no_data {
        text-align:center !important;
    }

    #style-11::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
    
    #style-11::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
        border: 1px solid #ccc;
    }

    #style-11::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(left, #fff, #e4e4e4);
        border: 1px solid #aaa;
    }

    #style-11::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }

    #style-11::-webkit-scrollbar-thumb:active {
        background: linear-gradient(left, #22ADD4, #1E98BA);
    }

    .toolbar_btn_fab {
        margin-right:10px;
    }

    #dt_btn_edit {
        font-size:18px !important;
    }

    #dt_btn_delete {
        font-size:18px !important;
    }

    #dt_btn_sync {
        font-size:18px !important;
    }

    #dt_btn_download {
        font-size:18px !important;
    }

    .no_data {
        color: #000 !important;
    }

    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }


</style>